import { Unity, useUnityContext } from "react-unity-webgl";
import { useEffect, useState,Fragment, useRef } from "react";
import LiveChat from './LiveChat/LiveChat';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { Loader } from "./loader";
import { PartiesUi } from "./PartiesUi";
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setUsers, User } from "../store/usersOnline";
import { userColorMap } from "./LiveChat/chatWindowComponents/ChatBubble";
import { setId, setSessionId, setUpdate } from "../store/appUser";
import { Profile } from "./Profile";
import { setWebsocket } from "../store/debug";


export interface RouteParams extends Record<string, string | undefined> {
  id: string;
}
export const GlobalRefs = {
  sendMessage: null as ReactUnityEventParameter | null,

}

type UnityConfig = {
  readonly streamingAssetsUrl?: string;
};

type disconnect = {
  timestamp: string,
  playerId: string
}


export const UnityClient: React.FC = () => {

  const colors = [
    "#00FF00", "#A078D2", "#64EAFF", "#E6E6FA", "#008B8B", "#90EE90", "#9400D3", "#87CEEB", "#006400", "#FFD700",
    "#FF69B4", "#FF4500", "#8B0000", "#B22222", "#FF6347", "#FF8C00", "#DAA520", "#B8860B", "#CD5C5C", "#DC143C",
    "#FFA07A", "#FF7F50", "#FFB6C1", "#FF1493", "#FF00FF", "#BA55D3", "#9370DB", "#8A2BE2", "#4B0082", "#483D8B",
    "#6A5ACD", "#7B68EE", "#4682B4", "#4169E1", "#0000FF", "#1E90FF", "#00BFFF", "#5F9EA0", "#20B2AA", "#3CB371",
    "#2E8B57", "#006400", "#9ACD32", "#32CD32", "#00FF7F", "#00FA9A", "#66CDAA", "#8FBC8F", "#228B22", "#008000",
    "#808000", "#6B8E23", "#556B2F", "#8B4513", "#A52A2A", "#D2691E", "#CD853F", "#F4A460", "#DEB887", "#D2B48C",
    "#BC8F8F", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#FFE4B5", "#F0E68C", "#EEE8AA", "#FAFAD2", "#FFFFE0", "#FFFF00",
    "#FFD700", "#FFA500", "#FF4500", "#DC143C", "#FF6347", "#FF7F50", "#FF8C00", "#DAA520", "#B8860B", "#FFD700",
    "#FF69B4", "#FF1493", "#FF00FF", "#BA55D3", "#9370DB", "#8A2BE2", "#4B0082", "#483D8B", "#6A5ACD", "#7B68EE",
    "#4682B4", "#4169E1", "#0000FF", "#1E90FF", "#00BFFF", "#5F9EA0", "#20B2AA", "#3CB371", "#2E8B57", "#006400"
];


  const { loader,data, framework, code, streamingAssests, moduleId } = useAppSelector(state => state.scene)

  const { unityProvider, isLoaded, loadingProgression, sendMessage, requestPointerLock } = useUnityContext({
        loaderUrl: loader,
        dataUrl:  data,
        frameworkUrl:  framework,
        codeUrl:  code,
        streamingAssetsUrl: streamingAssests
  });

  const dispatch = useAppDispatch()
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [unityComponent, setUnityComponent] = useState<JSX.Element | null>(null);
  const { player, update } = useAppSelector(state => state.appUser)
  const [avatarId, setAvatarId] = useState(null)
  const loadingPercentage = Math.round(loadingProgression * 100);
  const [showUnityClient, setShowUnityClient] = useState(true);
  const [websocketOpen, setWebsocketOpen] = useState(false);
  const [room, setRoom] = useState(null);
  const { users } = useAppSelector((state) => state.usersOnline)
  const usersRef = useRef(users)
  const updateRef = useRef(update)

  //Update refs
  useEffect(() => {
    usersRef.current = users
    updateRef.current = update
  },[users, update])

  // check cookies and send to unity
  useEffect(()=> {
    // let cookie = Cookies.get("avatarUrl")
    // let username = Cookies.get("username")
    
        if(isLoaded){
          let data = {
            displayName: player.displayName,
            avatarUrl: player.glbUrl,
            colour: player.colour
          }
          console.log("Avatar Data to Unity")
          console.log(data)
          sendMessage("ReactClientManager", "SetLocalAvatar", JSON.stringify(data));
          sendMessage("ReactClientManager", "OnAvatarCreationCompleted");
        } 
        
    }, [avatarId, setShowUnityClient, isLoaded]);
    
  // prepare the unity component    
  useEffect(() => {
    setUnityComponent(<Fragment><Unity className='unity'  unityProvider={unityProvider} ref={canvasRef}  tabIndex={1} /></Fragment>)
  }, [unityProvider]);
    
  useEffect(() => {
    if(isLoaded){
      sendMessage("ReactClientManager", "SetSTOMPConnectHeaders", JSON.stringify({
        authToken: player.authToken,
        moduleId: moduleId
      }))
    }
  },[isLoaded])
    
  useEffect(() => {
    window.sendMessage = sendMessage
  });

  // subscribe to initialise and setup other subscriptions
  useEffect(() => {
    if(websocketOpen){
        window.STOMP.subscribe("/user/queue/initialise", (frame: any) => {
          setRoom(frame.body.room)
          subscribeToUpdateAndDisconnect(frame.body.room)
          updateAvatars(frame.body.avatarDefinitions)
          dispatch(setWebsocket("Connected"))
          dispatch(setSessionId(frame.body.sessionId))
        })
    }
  }, [websocketOpen])

  // Monitor websocket state
  useEffect(() => {
    const handleWebSocketOpened = () => setWebsocketOpen(true);
    const handleWebSocketClosed = () => setWebsocketOpen(false);

    window.STOMP.addEventListener(window.STOMPEvents.on_websocket_opened, handleWebSocketOpened);
    window.STOMP.addEventListener(window.STOMPEvents.on_websocket_closed, handleWebSocketClosed);

    // Cleanup event listeners on component unmount
    return () => {
      window.STOMP.removeEventListener(window.STOMPEvents.on_websocket_opened, handleWebSocketOpened);
      window.STOMP.removeEventListener(window.STOMPEvents.on_websocket_closed, handleWebSocketClosed);
    };
  }, []);

  // Pointer Lock Logic
  useEffect(() => {
    if(canvasRef.current !== null && isLoaded){
      canvasRef.current.addEventListener("click", async function () {
        if (typeof window.UNITY_CLIENT_WANTS_CURSOR_LOCK !== "undefined" && window.UNITY_CLIENT_WANTS_CURSOR_LOCK) {
          await requestPointerLock();
        }
      });
      var reportPointerLockState = function () {
	
        // tell the unity client the current cursor lock state
        sendMessage("ReactClientManager", "SetCurrentPointerLockState", typeof document.pointerLockElement === "undefined" || document.pointerLockElement === null ? 0 : 1);
        
        // if the cursor is currently shown, check if the unity client is reporting that it should be a hover cursor
        var isCursorShown = typeof window.UNITY_CLIENT_WANTS_CURSOR_LOCK === "undefined" || !window.UNITY_CLIENT_WANTS_CURSOR_LOCK;
        var wantsHoverCursor = isCursorShown && typeof window.UNITY_CLIENT_WANTS_HOVER_CURSOR !== "undefined" && window.UNITY_CLIENT_WANTS_HOVER_CURSOR;
        
        // apply the hover cursor or, as long as the cursor is currently shown rather than hidden, default it
        if (wantsHoverCursor) {
          if(canvasRef.current != null){
            canvasRef.current.style.cursor = "pointer";
          }  
          
        }
        else if (isCursorShown) {
          if(canvasRef.current != null){
            canvasRef.current.style.cursor = "default";
          }
        }
        
        // run again the next animation frame
        window.requestAnimationFrame(reportPointerLockState);
      };
      window.requestAnimationFrame(reportPointerLockState);
    }
    
  },[isLoaded])

  // handle Subscriptions
  function subscribeToUpdateAndDisconnect(room:string){
    window.STOMP.subscribe("/topic/update-avatar/" + room, (frame: any) => {
      updateAvatars(frame.body)
    })
    window.STOMP.subscribe("/topic/disconnect-user/" + room, (frame: any) => {
      disconnectAvatars(frame.body)
    })
  }

  // update avatars online
  function updateAvatars(avatars: User[]){
    console.log(avatars)
    
    let currentUsers = [...usersRef.current]
    console.log(currentUsers)
    avatars.forEach((user: User) => {
      const existingUserIndex = currentUsers.findIndex(existingUser => existingUser.playerId === user.playerId);

      if (existingUserIndex === -1) {
        currentUsers.push(user);
      } else {
        currentUsers[existingUserIndex] = user;
      }
      
      if(user.avatarUrl === player.glbUrl && user.displayName === player.displayName && user.colour === player.colour){
        console.log("Setting PlayerId: " + user.playerId)
        dispatch(setId(user.playerId))
      }
      
    })
    dispatch(setUsers(currentUsers))
    dispatch(setUpdate(!updateRef.current))
  }
  

  // handle Disconnects
  function disconnectAvatars(avatars: disconnect[]){
    let currentUsers = [...usersRef.current]
    const disconnectIds = new Set(avatars.map(avatar => avatar.playerId));
    currentUsers = currentUsers.filter(user => !disconnectIds.has(user.playerId));
    dispatch(setUsers(currentUsers))
  }

      return(
       <Fragment>  {!isLoaded && showUnityClient && (
            <div className="loader-container">
            <Loader loadingPercentage={loadingPercentage}/>
        </div>
          )}{ showUnityClient && unityComponent}  
         
         {isLoaded && websocketOpen && (
            <>
              <Profile sendMessage={sendMessage}/>
              <PartiesUi />
              {room && (<div>
                          <LiveChat room={room} />
                        </div>
                        )}
            </>
          )}

        </Fragment> 
      )
}