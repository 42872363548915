import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTransition,animated } from 'react-spring';
import { useDispatch } from 'react-redux';
import styles from '../../styles.module.css';
import { useAppSelector } from '../../store/hooks';

type Props = {
  circleScalingFactor: number
}
type Item = string;

export const LandingText: React.FC<Props> = ({circleScalingFactor}) => {

    const { player } = useAppSelector((state) => state.appUser)
    const ref = useRef<NodeJS.Timeout[]>([]);
    const dispatch = useDispatch()
    const [items, set] = useState<Item[]>([]);
    const transitions = useTransition(items, {
    from: {
      color: '#1d4e76',
      innerHeight: 120 * circleScalingFactor
    },
    enter: [
      { opacity: 1, height: 120 * circleScalingFactor, innerHeight: 120 * circleScalingFactor },
    ],
    leave: [{ color: '#fbfbfb' }, { innerHeight: 0 }, { opacity: 0, height: 0 }],
    update: { color: '#fbfbfb' },
  })

  

  const reset = useCallback(() => {
    ref.current.forEach(clearTimeout)
    ref.current = []
    set([])
    ref.current.push(setTimeout(() => set([ `Hi ${player.displayName ? player.displayName : ""}`, `` ]), 1000))
    ref.current.push(setTimeout(() => set([ `Hi ${player.displayName ? player.displayName : ""}`,'Welcome']), 3000))
    ref.current.push(setTimeout(() => set([ 'Welcome', 'To Vegas City']), 5000))
    ref.current.push(setTimeout(() => set([ 'To Vegas City','Enter a 3D world']), 7000))
    ref.current.push(setTimeout(() => set([ 'Enter a 3D world', 'Meet New Friends' ]), 9000))
    ref.current.push(setTimeout(() => set([ 'Meet New Friends', 'Explore The Metaverse']), 11000)) 
    ref.current.push(setTimeout(() => set([ 'Explore The Metaverse', 'Click Begin to Start']), 13000))
  }, [])

 
  useEffect(() => {
    reset()
    return () => ref.current.forEach(clearTimeout)
  }, [])

  return(
        <div>
        {transitions(({ innerHeight , ...rest }, item) => (
          <animated.div className={styles.transitionsItem} style={rest}>
            <animated.div style={{ overflow: 'hidden', height: innerHeight }}>{item}</animated.div>
          </animated.div>
        ))}
    </div>
  )
}