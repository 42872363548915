import { useSpring, animated } from '@react-spring/web';
import React from 'react';

type Props = {
  circleScalingFactor: number
}
export const PulsingDiv: React.FC<Props>= ({circleScalingFactor}) => {

    const styles = useSpring({
        loop: false,
        to: [{ scale: 2 }, { scale: 1.5 }],
        from: { scale: 1 },
        config: { duration: 1000 }, 
      });
    
      return (
        <animated.div
          style={{
            width: 100 * circleScalingFactor,
            height: 100 * circleScalingFactor,
            borderRadius: '50%',
            boxShadow: '0px 0px 50px 10px #1d4e76',
            cursor: 'pointer',
            display: 'flex',           
            alignItems: 'center',      
            justifyContent: 'center',  
            ...styles,
          }}
        ><p style={{position:'relative', top: '0%', color: 'white'}}>Begin</p></animated.div>
    );
};