import React, { useEffect, useRef, useState } from 'react'
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax'
import styles from '../../styles.module.css'
import { AvatarPage } from '../AvatarPage/AvatarPage'
import WelcomePage from './WelcomePage'
import ReadyPlayerMeCreator from '../ReadyPlayerMe/ReadyPlayerMeCreator'
import { useAppDispatch } from '../../store/hooks'
import { setPlayer } from '../../store/appUser'
import { SceneManager } from '../ScenePicker/SceneManager'

type Props = {
  scrollTo: number | null
}

export const ParallaxMenu: React.FC<Props> = ({ scrollTo }) => {

  const parallax = useRef<IParallax>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const dispatch = useAppDispatch()

  const scroll = (to: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(to)
      setCurrentPage(to)
    }
  }

  useEffect(() => {
    if (scrollTo) {
      scroll(scrollTo)
    }
  }, [])

  useEffect(() => {
    window.navigate = scroll
  }, [])

  useEffect(() => {
    fetchData(window.location.origin + "/user-profile")
      .then(data => {
        console.log('Data received:', data);

        dispatch(setPlayer(data))

        if (data.glbUrl) {
          let modelId = data.glbUrl?.split('/').pop()?.split('.')[0] ?? null;
          window.avatarId = modelId
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  async function fetchData(url: string): Promise<any> {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  return (
    <div >
      <Parallax className={styles.containerPalx} ref={parallax} pages={4} horizontal style={{ backgroundColor: 'black' }}>
        <ParallaxLayer offset={0} speed={0.2}>
          <WelcomePage navigate={scroll} to={1} />
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={0.2}>
          <SceneManager navigate={scroll} to={2} />
        </ParallaxLayer>
        <ParallaxLayer offset={2} speed={0.6}>
          <AvatarPage navigate={scroll} to={0} editor={3} currentPage={currentPage} />
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={0.6}>
          <ReadyPlayerMeCreator
            style={{ width: "100vw", height: "100vh", border: "none", margin: 0, zIndex: 4500000, borderRadius: 'none' }}
            width='100vw'
            height='100vh'
            close={null}
            isModal={false}
            sendMessage={null}
          />
        </ParallaxLayer>
      </Parallax>
    </div>
  )
}