import React from "react"
import { useAppSelector } from "../../store/hooks"
import { SceneTile } from "./SceneTile"
import  "./ScenePicker.css"

type Props = {
    navigate: Function,
    to:number
}
export const SceneManager: React.FC<Props> = ({navigate,to}) => {
    const { sceneTiles } = useAppSelector(state => state.scene);
    
    return (
        <div>
            <h1 style={{textAlign: 'center', color: 'white', margin: 'auto'}}>Select A Scene</h1>
            <div className="scene-manager">
                {
                    sceneTiles.map(tile => (
                        <SceneTile key={tile.name} scene={tile} navigate={navigate} to={to}/>
                    ))
                }
            </div>
        </div>
    )
}
