import { createSlice} from "@reduxjs/toolkit";

interface SceneState {
    name: string,
    moduleId: string,
    loader: string,
    data: string,
    framework: string,
    code:string,
    streamingAssests: string,
    sceneTiles: SceneTiles[]
}

export interface Style {
    primary_color: string; 
    secondary_color: string; 
    accent_color: string; 
    highlight_color: string; 
    error_color: string; 
    background: string;
    text:string
}

export interface SceneTiles {
    name: string,
    moduleId: string,
    image: string,
    loader: string | undefined,
    data: string | undefined,
    framework: string | undefined,
    code:string | undefined,
    streamingAssests: string | undefined,
    style: Style
}

const initialState: SceneState = {
    name: "",
    moduleId: "",
    loader: "",
    data: "",
    framework: "",
    code:  "",
    streamingAssests: "",
    sceneTiles: [
        {
            name: "Nugs.net",
            moduleId: "f39d4d5d-a9d5-4d2b-b77a-9ce883767819",
            image: "/images/audience.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_NUGS,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_NUGS,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_NUGS,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_NUGS,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_NUGS,
            style: {
                primary_color: "#660000", 
                secondary_color: "#990033",  
                accent_color: "#5F021F", 
                highlight_color: "#FF9000",  
                error_color: "#8C001A", 
                background: "/images/concert.jpg",
                text: "white"
            }
        },
        {
            name: "Hoops",
            moduleId: "fdb86d38-6f4c-43f5-b9cd-403caaa3b790",
            image: "/images/basketball.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_HOOPS,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_HOOPS,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_HOOPS,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_HOOPS,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_HOOPS,
            style: {
                primary_color: "#F35B04", 
                secondary_color: "#F18701",  
                accent_color: "#F7B801", 
                highlight_color: "#7678ED",  
                error_color: "#3D348B", 
                background: "/images/basketballBackground.jpg",
                text: "grey"
            }
        },
        {
            name: "Golf",
            moduleId: "19b58188-ed65-43d8-a835-1f2aa3e99d4b",
            image: "/images/golfer.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_GOLF,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_GOLF,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_GOLF,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_GOLF,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_GOLF,
            style: {
                primary_color: "#30362F", 
                secondary_color: "#625834",  
                accent_color: "#A59132", 
                highlight_color: "#FFFBDB",  
                error_color: "#DA7422", 
                background: "/images/golf.jpg",
                text: "black"
            }
        },
        {
            name: "Champions",
            moduleId: "f97244e2-16d0-4f0d-91cb-883611db0233",
            image: "/images/boxing.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_CHAMPIONS,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_CHAMPIONS,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_CHAMPIONS,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_CHAMPIONS,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_CHAMPIONS,
            style: {
                primary_color: "#E3170A", 
                secondary_color: "#E16036",  
                accent_color: "#D6A99A", 
                highlight_color: "#D6CBC1",  
                error_color: "#CDD6D0", 
                background: "/images/boxing-glove.jpg",
                text: "white"
            }
        }
    ]
}

export const sceneStateSlice = createSlice({
    name:"scenes",
    initialState,
    reducers: {
        setSceneState: (state, action) => {
            state = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setModuleId: (state, action) => {
            state.moduleId = action.payload
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        setFramework: (state, action) => {
            state.framework = action.payload
        },
        setCode: (state, action) => {
            state.code = action.payload
        },
        setStreamingAssets: (state, action) => {
            state.streamingAssests = action.payload
        }
    }
});

export const { setName, setModuleId, setCode,setData,setFramework,setLoader,setSceneState,setStreamingAssets } = sceneStateSlice.actions;

export default sceneStateSlice.reducer;