import { useEffect, useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AvatarPage } from "./AvatarPage/AvatarPage";
import { ParallaxMenu } from "./LandingPage/ParallaxMenu";
import { useAppDispatch } from "../store/hooks";
import { setPlayer } from "../store/appUser";

export const globalIsHost = {
    isHost: false
} 

export const InboundLinkRouting: React.FC = () => {

    const {id} = useParams<RouteParams>();
    const [hasCookie, setHasCookie] = useState(true);
    const hostId = "b2124128-a607-487f-b9de-33b03750cab9";
    const dispatch = useAppDispatch();

    useEffect(() => {
        console.log("The User is HOST: " + globalIsHost.isHost)
        if(globalIsHost.isHost){
            Cookies.set("isHost", globalIsHost.isHost.toString())
        }
    }, [globalIsHost.isHost])

    // useEffect(() => {
    //     let avatarUrl = Cookies.get("avatarUrl");
    //     let username = Cookies.get("username");

    //     if (avatarUrl === undefined || username === undefined) {
    //         setHasCookie(false);
    //     }
    // }, []);

    useEffect(() => {
            fetchData(window.location.origin + "/user-profile")
            .then(data => {
                console.log('Data received:', data);

                dispatch(setPlayer(data))
                if(data.glbUrl === null || data.displayName == null){
                    setHasCookie(false)
                }
                if(data.glbUrl){
                    let modelId = data.glbUrl?.split('/').pop()?.split('.')[0] ?? null;
                    window.avatarId = modelId
                }
              })
              .catch(error => {
                  console.error('Error:', error);
        });
  }, []);

  async function fetchData(url: string): Promise<any> {
    try {
        const response = await fetch(url, {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; 
    }
}

    if(id === hostId){
        globalIsHost.isHost = true
    }

    return(
        <>
            {
                hasCookie ? <UnityClient /> : <ParallaxMenu scrollTo={1}/>
            }
        </>
    )
}