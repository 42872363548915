import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LandingText } from './LandingText';
import { PulsingDiv } from './PulsingDiv';

interface WindowSize {
    width: number,
    height: number
  }
  
type WelcomeProps = {
    navigate: Function,
    to:number
}

  const WelcomePage: React.FC<WelcomeProps> = ({navigate, to}) => {

    const [display, setDisplay] = useState("")
    const dispatch = useDispatch()
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    const circleScalingFactor = Math.min(windowSize.width / 1800, windowSize.height / 1800);


    return(
        <div className='App'>  
            
            <div className='welcome'>
                <div className='welcome-card'>    
                    <LandingText circleScalingFactor={circleScalingFactor}/>
                    
                </div>
            </div>
            <div className="next" onClick={() => navigate(to)} style={{display: display}}>
                <PulsingDiv circleScalingFactor={circleScalingFactor} />
            </div>

        </div>
    )
}

export default WelcomePage;
