import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ChatIcon from '@mui/icons-material/Chat';
import { DebugPanel } from '../Debug/DebugPanel';
import { setWebsocket } from '../../store/debug';
import { setMessageHistory, setUnityMessage } from '../../store/messages';
import "./Chat.css"
import { SubscriptionHandler } from './chatWindowComponents/SubscriptionHandler';


type Props = {
  room: string
}
const LiveChat: React.FC<Props> = ({ room }) => {

  const dispatch = useAppDispatch()
  const [show, setShowChat] = useState(false)
  const [showDebug, setShowDebug] = useState(false)
  const [showPending, setShowPending] = useState("")
  const [isFocused, setIsFocused] = useState(false);
  const [isPositionToggled, setIsPositionToggled] = useState(false);
  const { messageHistory, unityMessage } = useAppSelector((state) => state.messages)
  const { player } = useAppSelector((state) => state.appUser)
  const { users } = useAppSelector(state => state.usersOnline);
  var location = window.location;
  var baseUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");

  // Refs
  const showRef = useRef(show);
  const focusRef = useRef(isFocused)
  const messageRef = useRef(messageHistory)

  const usersOnlineRef = useRef(users)

  // Check and update Refs
  useEffect(() => {
    showRef.current = show;
    focusRef.current = isFocused;
    messageRef.current = messageHistory;
    usersOnlineRef.current = users;
  }, [show, isFocused, player, messageHistory, users]);

  // Listen For "Enter" to Show and hide Chat
  useEffect(() => {
    const handleKeyPress = (event: { key: string; }) => {
      if (event.key === 'Enter' && !focusRef.current) {
        handleShowChat()
      }
    };
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);



  // incoming message listener
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== baseUrl) return;
      const message = event.data;
      if (message === "debug") {
        setShowDebug(true)
      } else if (message === "Connected") {
        dispatch(setWebsocket("Connected"))
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [baseUrl]);


  //listen for window open chat change and show chat accordingly
  useEffect(() => {
    const handleOpenChatChange = () => {
      if (window.openChat) {
        setShowChat(true);
        setShowPending("none");
        newMessage();

      }
    };

    window.addEventListener('openChatChange', handleOpenChatChange);

    return () => {
      window.removeEventListener('openChatChange', handleOpenChatChange);
    };
  }, []);

  useEffect(() => {
    console.log("Message: " + unityMessage)
    let data;
    try {
      data = JSON.parse(unityMessage);
    } catch (e) {
      console.error("Invalid JSON received:", unityMessage);
      return;
    }

    if (data.type === "unityMessage") {
      formatUnityMessages(unityMessage);
    }
  }, [unityMessage]);

  // Handle Messages From Unity/System/Party
  function formatUnityMessages(message: string) {
    let data = JSON.parse(message)
    let currentMessages: Array<any> = [...messageRef.current];
    let messageUnity = {
      from: "System",
      msg: data.msg.textMessage,
      playerId: "54321",
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      avatar: getAvatar("54321"),
      partyMessage: data.msg,
      responded: data.responded
    };

    const messageExists = currentMessages.some(msg => msg.msg === messageUnity.msg);
    if (!messageExists) {
      if (currentMessages.length > 200) {
        currentMessages.shift();
      }
      currentMessages.push(messageUnity);
      dispatch(setMessageHistory(currentMessages));
    }
  }

  // get the Avatar images from the users Online when message recieved
  function getAvatar(id: string): string {
    let url = usersOnlineRef.current.find(user => user.playerId === id)?.avatarUrl.replace(".glb", ".png");
    return url === undefined ? "https://models.readyplayer.me/66a7a3fc922f759d2bf57a7b.png" : url;
  }


  // display chat on button click
  function handleShowChat() {
    setShowChat(!showRef.current)
    setShowPending("none")
    setIsPositionToggled(!isPositionToggled)
  }


  // Show alert for unread Messages
  function newMessage() {
    if (!showRef.current) {
      setShowPending("")
    }
  }


  return (
    <div >
      <div
        className="chat"
        style={{
          width: show ? '60%' : '0%',
          display: show ? "" : "none",
          transition: 'height 0.3s, width 0.3s',
          minWidth: '100px',
          maxWidth: '400px'
        }}
      >
        <SubscriptionHandler setIsFocused={setIsFocused} newMessage={newMessage} getAvatar={getAvatar} room={room} />
      </div>
      <div>
        <div style={{ display: 'inline-block', position: 'fixed', bottom: '2%', right:  isPositionToggled ? "450px" : "30px" }}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: 'green',
              position: 'absolute',
              bottom: '50px',
              right: '-18px',
              display: showPending,
              zIndex: 260000
            }}
          ></div>
          <button
            onClick={handleShowChat}
            className='unityBtn'
            style={{ position: 'relative', zIndex: 1 }}
          >
            <ChatIcon />
          </button>
        </div>
      </div>
      {showDebug && (<DebugPanel setShowDebug={setShowDebug} />)}
    </div>
  )
}

export default LiveChat;