import { useState, useEffect } from "react";
import { MicNoneRounded } from "@mui/icons-material";
import Cookies from "js-cookie";

export const PartiesUi: React.FC = () => {

    const [display, setDisplay] = useState(false)

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `${process.env.PUBLIC_URL}/parties-setup.js`;
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        let host = Cookies.get("isHost")
        if(host){
            window.isHost = true
            setDisplay(true)
        }
    })

    return (
        <div>
             <a
                href="#"
                id="btnShowCallAudioSettings"
                style={{
                    backgroundColor: 'var(--highlight-color)',
                    borderRadius: '50%',
                    border: '2px var(--primary-color) solid',
                    color: 'var(--primary-color)',
                    left: '16px',
                    outline: 'none',
                    padding: '16px',
                    position: 'fixed',
                    textDecoration: 'none',
                    top: '16px',
                }}
                >
                <MicNoneRounded/>
            </a>
            { display && (<a
                href="#"
                id="btnStartCall"
                style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    borderRadius: '8px',
                    color: 'rgb(100,234,255)',
                    left: '50%',
                    outline: 'none',
                    padding: '16px',
                    position: 'fixed',
                    textDecoration: 'none',
                    top: '16px',
                    transform: 'translate(-50%, 0)',
                    display: 'none'
                }}
            >
                Start Call
            </a>)}

            <span
                id="lblIncomingCallInfo"
                style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    borderRadius: '10px',
                    left: '50%',
                    lineHeight: '1',
                    padding: '8px',
                    position: 'fixed',
                    top: '16px',
                    transform: 'translate(-50%, 0)',
                    color: 'rgb(100,234,255)',
                    border: '2px white dotted',
                    display: 'none'
                }}
            >
                Incoming call from [[display_name]]...
            </span>
            <a
                href="#"
                id="btnAcceptCall"
                style={{
                    backgroundColor: '#33cc77',
                    borderRadius: '8px',
                    color: '#fff',
                    left: 'calc(50% - 8px)',
                    outline: 'none',
                    padding: '16px',
                    position: 'fixed',
                    textDecoration: 'none',
                    top: '60px',
                    transform: 'translate(-100%, 0)',
                    display: 'none'
                }}
            >
                Accept Call
            </a>
            <a
                href="#"
                id="btnRejectCall"
                style={{
                    backgroundColor: '#cc3377',
                    borderRadius: '8px',
                    color: '#fff',
                    left: 'calc(50% + 8px)',
                    outline: 'none',
                    padding: '16px',
                    position: 'fixed',
                    textDecoration: 'none',
                    top: '60px',
                    display: 'none'
                }}
            >
                Reject Call
            </a>

            <div
                id="cntCallInfo"
                style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    color: 'rgb(100,234,255)',
                    borderRadius: '10px',
                    left: '50%',
                    lineHeight: '1',
                    padding: '8px',
                    position: 'fixed',
                    top: '16px',
                    transform: 'translate(-50%, 0)',
                    border: '2px white dotted',
                    display: 'none'
                }}
            >
                <h1
                    style={{
                        fontSize: '150%',
                        lineHeight: '1',
                        margin: '0',
                        padding: '0',
                        textAlign: 'center',
                        color: 'rgb(100,234,255)',
                        display: 'none'
                    }}
                >
                    Active Call
                </h1>
                <table style={{ color: 'rgb(100,234,255)' }}>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Connected</th>
                        {display && (
                            <>
                                <th>Joined</th>
                                <th>Audio</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>[[display_name]]</td>
                        <td>[[is_connected]]</td>
                        {display && (
                            <>
                                
                                <td>[[is_joined]]</td>
                                <td>[[audio_status]]</td>
                            </>
                        )}
                    </tr>
                </tbody>
            </table>
                
            <div style={{ textAlign: 'center' }}>
            { display && ( <a
                        href="#"
                        id="btnEndCall"
                        style={{
                            backgroundColor: '#cc3377',
                            borderRadius: '8px',
                            color: '#fff',
                            display: 'inline-block',
                            left: 'calc(50% + 8px)',
                            margin: '8px 0 0',
                            outline: 'none',
                            padding: '16px',
                            textDecoration: 'none',
                            top: '60px'
                        }}
                    >
                        End Call
                    </a>)}
                    <br /><br />
                    Audio problems?{' '}
                    <a
                        href="#"
                        id="btnResetConnection"
                        style={{ color: '#cc3377', 
                            textDecoration: 'none'
                         }}
                    >
                        Reset your connection
                    </a>
                </div>
            </div>
            <div
                id="ctnCallAudioSettings"
                style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    color: 'var(--primary-color)',
                    bottom: 0,
                    left: 0,
                    position: 'fixed',
                    right: 0,
                    textAlign: 'center',
                    top: 0,
                    zIndex: 999,
                    display: 'none'
                }}
                >
            <div
                style={{
                backgroundColor: 'rgba(34, 36, 48, 0.7)',
                borderRadius: '15px',
                border: '2px var(--primary-color) solid',
                
                left: '50%',
                padding: '8px',
                position: 'fixed',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                }}
            >
        <h2>Call Audio Settings</h2>
        <table>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>
                <h3>Microphone</h3>
              </td>
              <td rowSpan={3}>&nbsp;</td>
              <td style={{ width: '50%' }}>
                <h3>Headphones/Speakers</h3>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="ddlAudioInputDevice">Input Device:</label>
                <br />
                <select id="ddlAudioInputDevice" name="ddlAudioInputDevice"></select>
              </td>
              <td>
                <label htmlFor="ddlAudioOutputDevice">Output Device:</label>
                <br />
                <select id="ddlAudioOutputDevice" name="ddlAudioOutputDevice"></select>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  id="btnTestAudioInputDevice"
                  href="#"
                  style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    borderRadius: '8px',
                    color: 'var(--primary-color)',
                    border: '2px var(--primary-color) solid',
                    display: 'none',
                    outline: 'none',
                    padding: '16px',
                    textDecoration: 'none'
                  }}
                >
                  Test
                </a>
              </td>
              <td>
                <a
                  id="btnTestAudioOutputDevice"
                  href="#"
                  style={{
                    backgroundColor: 'rgba(34, 36, 48, 0.7)',
                    borderRadius: '8px',
                    color: 'var(--primary-color)',
                    border: '2px white dotted',
                    display: 'none',
                    outline: 'none',
                    padding: '16px',
                    textDecoration: 'none',
                  }}
                >
                  Test
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <a
          id="btnHideCallAudioSettings"
          href="#"
          style={{
            backgroundColor: 'transparent',
            borderRadius: '8px',
            color: 'var(--primary-color)',
            display: 'inline-block',
            outline: 'none',
            padding: '16px',
            textDecoration: 'none',
          }}
        >
        <div id='btn-click-image'></div><span style={{position: 'relative', top: '12px', left: '-25px'}}>CLICK WHEN DONE</span> 
        </a>
      </div>
    </div>
    </div>
    );
}