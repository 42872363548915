import { Route, Routes} from 'react-router-dom';
import './App.css';
import { Fragment, useEffect } from "react";
import { UnityClient } from './components/UnityClient';
import { ParallaxMenu } from './components/LandingPage/ParallaxMenu';
import { InboundLinkRouting } from './components/InboundLinkRouting';

const App: React.FC = () => {
  
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
      } 
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  return (
      <Fragment>
                <Routes> 
                    <Route
                      path="/"
                      element={
                          <ParallaxMenu scrollTo={null}/> }
                      />
                    <Route
                      path="/mv/unity"
                      element={<UnityClient/>}
                    />
                    <Route 
                      path="/mv/:id"
                      element={
                        <InboundLinkRouting />
                      }
                    />
                </Routes>
        </Fragment>
  );
}

export default App;
