import React from "react";
import { SceneTiles, setCode, setData, setFramework, setModuleId, setLoader, setName, setStreamingAssets } from "../../store/scenes";
import { useAppDispatch } from "../../store/hooks";

type Props = {
    scene: SceneTiles
    navigate: Function,
    to:number
}

export const SceneTile: React.FC<Props> = ({scene, navigate, to}) => {

    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(setLoader(scene.loader))
        dispatch(setData(scene.data))
        dispatch(setFramework(scene.framework))
        dispatch(setCode(scene.code))
        dispatch(setStreamingAssets(scene.streamingAssests))
        dispatch(setName(scene.name))
        dispatch(setModuleId(scene.moduleId))
        setSyle()
        navigate(to)
    }

    const setSyle = () => {
        document.documentElement.style.setProperty('--background-avatar', `url(${scene.style.background})`);
        document.documentElement.style.setProperty('--primary-color', scene.style.primary_color);
        document.documentElement.style.setProperty('--secondary-color', scene.style.secondary_color);
        document.documentElement.style.setProperty('--accent-color', scene.style.accent_color);
        document.documentElement.style.setProperty('--highlight-color', scene.style.highlight_color);
        document.documentElement.style.setProperty('--error-color', scene.style.error_color);
        document.documentElement.style.setProperty('--background-avata', scene.style.text);
        document.documentElement.style.setProperty('--text-color', scene.style.text);
    }

    return (
        <div className="scene-tile" style={{ backgroundImage: `url(${scene.image})` }} onClick={handleClick}>
            <h1 style={{color: 'white', backgroundColor: 'black', padding: '5px'}}>{scene.name}</h1>
        </div>
    )
}
